<template>
    <div >
      <Header />
    
    <v-container>
        <v-text-field
            v-model="orderId"
            label="Bestellnummer"
            outlined
            @keyup.enter="fetchOrderData"></v-text-field>
            <v-card class="mt-4" v-if="!order">
      <v-card-title>
        Offene Bestellungen
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="openOrders"
        :search="search"
        :loading="loadingOpenOrders"
        :items-per-page="-1"
        class="elevation-1"
      >
        <template v-slot:item.grandtotal="{ item }">
          {{ formatPrice(item.grandtotal) }}
        </template>
        <template v-slot:item.create_timestamp="{ item }">
          {{ formatDate(item.create_timestamp) }}
        </template>
        <template v-slot:item.acsImported="{ item }">
          <v-chip :color="getAcsStatusColor(item.acsImported)" dark>{{ item.acsImported ? "ACS Importiert" : "Nicht importiert" }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn small @click="loadOrderDetails(item.receipt_id)">
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
      <v-card v-if="order">
        <v-card-title>
          <v-icon @click="reset" large class="mr-2">arrow_back</v-icon>
          <v-spacer></v-spacer>
          <v-chip :color="getAcsStatusColor(order.acsImported)" dark>{{ order.acsImported ? "ACS Importiert" : "Nicht importiert" }}</v-chip>
          <v-chip :color="getStatusColor(order.status)" dark>{{ order.status }}</v-chip>
        </v-card-title>
  
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <h3>Kundeninformationen</h3>
              <v-form>
                <v-text-field
                  v-model="editableCustomerInfo.firstName"
                  label="Vorname"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.lastName"
                  label="Nachname"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.street"
                  label="Straße"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.houseNumber"
                  label="Hausnummer"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.additionalInfo"
                  label="Zusätzliche Informationen"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.zipCode"
                  label="PLZ"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.city"
                  label="Stadt"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.country"
                  label="Land"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="editableCustomerInfo.email"
                  label="E-Mail"
                  outlined
                  dense
                ></v-text-field>
              </v-form>
            </v-col>
  
            <v-col cols="12" md="6">
              <h3>Bestellinformationen</h3>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bestellnummer</v-list-item-title>
                    <v-list-item-subtitle>{{ order.receipt_id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bestelldatum</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(order.create_timestamp) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Zahlungsmethode</v-list-item-title>
                    <v-list-item-subtitle>{{ order.payment_method }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
  
          <v-divider class="my-4"></v-divider>
          <h3>Bestellte Artikel</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Artikel</th>
                <th>Menge</th>
                <th>Preis</th>
                <th>Gesamt</th>
                <th>SKU</th>
                <th>Status</th>
                <th>Personalisierung</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.transactions" :key="item.transaction_id">
                <td>{{ item.sku }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ getDiscountedPrice(item) }}</td>
                <td>{{ formatPrice(calculateTotal(item)) }}</td>
                <td>{{ getInternalSKU(item.sku) }}</td>
                <td>
                  <v-chip
                    :color="productExists(item.sku) ? 'green' : 'red'"
                    text-color="white"
                    small
                  >
                    {{ productExists(item.sku) ? 'Vorhanden' : 'Nicht vorhanden' }}
                  </v-chip>
                </td>
                <td>
                  <v-btn small @click="openPersonalizationDialog(item)">
                    Bearbeiten
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
  
          <v-divider class="my-4"></v-divider>
  
          <v-row>
            <v-col cols="12" md="6">
              <h3>Versandinformationen</h3>
              <v-list dense v-if="order.shipments && order.shipments.length">
                <v-list-item v-for="shipment in order.shipments" :key="shipment.receipt_shipping_id">
                  <v-list-item-content>
                    <v-list-item-title>{{ shipment.carrier_name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      Trackingnummer: {{ shipment.tracking_code }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      Versanddatum: {{ formatDate(shipment.shipment_notification_timestamp) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p v-else>Keine Versandinformationen verfügbar</p>
            </v-col>
  
            <v-col cols="12" md="6">
              <h3>Zahlungsübersicht</h3>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Zwischensumme</v-list-item-title>
                    <v-list-item-subtitle>{{ formatPrice(order.subtotal) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Versandkosten</v-list-item-title>
                    <v-list-item-subtitle>{{ formatPrice(order.total_shipping_cost) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Rabatt</v-list-item-title>
                    <v-list-item-subtitle>-{{ formatPrice(order.discount_amt) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Gesamtsumme</v-list-item-title>
                    <v-list-item-subtitle>{{ formatPrice(order.grandtotal) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  
     <!-- Personalisierungs-Dialog -->
<v-dialog v-model="personalizationDialog" max-width="1200px">
  <v-card>
    <v-card-title class="headline">
      Personalisierung bearbeiten
      <v-spacer></v-spacer>
      <v-btn icon @click="personalizationDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="5">
          <v-card outlined class="mb-4">
            <v-card-title class="subtitle-1 py-2 grey lighten-4">Aktuelle Personalisierung</v-card-title>
            <v-card-text>
              <v-textarea
                v-model="currentPersonalization"
                readonly
                outlined
                hide-details
                rows="4"
                dense
                class="font-weight-medium"
              ></v-textarea>
            </v-card-text>
          </v-card>

          <v-card v-if="svgOptions" outlined class="mb-4">
            <v-card-title class="subtitle-1 py-2 grey lighten-4">Verfügbare Optionen</v-card-title>
            <v-card-text class="pa-0">
              <v-list dense>
                <v-list-item v-for="(option, index) in svgOptions" :key="index" class="px-4 py-1">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{ option.label }}</v-list-item-title>
                    <v-list-item-subtitle class="caption grey--text">{{ option.field }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="7">
          <v-card outlined>
            <v-card-title class="subtitle-1 py-2 grey lighten-4">Personalisierung zuordnen</v-card-title>
            <v-card-text>
              <v-container class="pa-0">
                <v-row v-for="(line, index) in personalizationLines" :key="index" class="my-2" align="center">
                  <v-col cols="12" class="py-1 grey lighten-5">
                    <div class="overline mb-1">Feld {{ index + 1 }}</div>
                    <v-row align="center">
                      <v-col cols="5">
                        <v-select
                          v-model="line.label"
                          :items="availableFields"
                          label="Zielfeld auswählen"
                          dense
                          outlined
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="line.value"
                          :label="`Wert ${index + 1}`"
                          dense
                          outlined
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" class="pl-0">
                        <v-btn icon small @click="removePersonalizationLine(index)" class="mt-1">
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <div class="caption text-right grey--text pr-6 mt-1">{{ getFieldHint(line.label) }}</div>
                  </v-col>
                </v-row>
              </v-container>

              <div class="d-flex mt-4">
                <v-btn small color="primary" class="px-2" @click="addPersonalizationLine">
                  <v-icon small left>add</v-icon> Zeile hinzufügen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="info" @click="showSVGStudioPreview">
                  <v-icon small left>visibility</v-icon> SVG Studio Vorschau
                </v-btn>
              </div>

              <v-card v-if="preview" class="mt-4" flat outlined>
                <v-card-text class="text-center pa-2">
                  <v-img :src="preview" contain max-height="200" :aspect-ratio="16/9"></v-img>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="error" text @click="personalizationDialog = false">
        Abbrechen
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="savePersonalization(true)">
        Speichern & Schließen
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
  
      <!-- JSON Output -->
      <v-card class="mt-4" v-if="order">
        <v-card-title>JSON Output</v-card-title>
        <v-card-text>
          <v-textarea v-if="manipulatedJSON" v-model="manipulatedJSON" label="JSON"></v-textarea>

          <v-btn @click="manipulatedJSON = JSON.stringify(generatedJSON, null, 2)">Bearbeiten</v-btn>
          <v-btn v-if="manipulatedJSON" @click="manipulatedJSON = null">Abbrechen</v-btn>
          <v-btn v-if="manipulatedJSON" @click="generatedJSON = JSON.parse(manipulatedJSON); manipulatedJSON = null">Speichern</v-btn>
          <pre>{{ JSON.stringify(generatedJSON, null, 2) }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="importOrder" :loading="importing">
            Importieren
          </v-btn>
          <v-btn color="primary" @click="reset" :loading="importing">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
  
      <!-- Snackbar für Benachrichtigungen -->
      <v-snackbar v-model="snackbar" :color="snackbarColor" top>
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Schließen
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Header from "../header";

  export default {
    name: 'OrderDetailsPage',
    components: {
      Header,
    },

    data() {
      return {
        orderId: null,
        order: null,
        personalizationDialog: false,
        currentPersonalization: '',
        personalizationLines: [],
        svgOptions: null,
        currentItem: null,
        generatedJSON: null,
        manipulatedJSON: null,
        importing: false,
        snackbar: false,
        snackbarText: '',
        stockData: [],
        snackbarColor: 'success',
        preview: null,
        loading: false,
        editableCustomerInfo: {
          firstName: '',
          lastName: '',
          street: '',
          houseNumber: '',
          additionalInfo: '',
          zipCode: '',
          city: '',
          country: '',
          email: ''
        },
        openOrders: [],
        loadingOpenOrders: false,
        search: '',
        headers: [
          { text: 'Bestellnummer', value: 'receipt_id' },
          { text: 'Kunde', value: 'name' },
          { text: 'Gesamtpreis', value: 'grandtotal' },
          { text: 'Bestelldatum', value: 'create_timestamp' },
          { text: 'Status', value: 'acsImported' },
          { text: 'Aktionen', value: 'actions', sortable: false }
        ],
        standardFields: [
          { value: 'line1', text: 'Zeile 1 (line1)' },
          { value: 'line2', text: 'Zeile 2 (line2)' },
          { value: 'line3', text: 'Zeile 3 (line3)' },
          { value: 'line4', text: 'Zeile 4 (line4)' },
          { value: 'line5', text: 'Zeile 5 (line5)' },
          { value: 'motiv1', text: 'Motiv 1 (motiv1)' },
          { value: 'motiv2', text: 'Motiv 2 (motiv2)' },
          { value: 'color', text: 'Farbe (color)' }
        ]
      };
    },
    computed: {
      availableFields() {
        // Kombination aus Standardfeldern und dynamischen SVG-Optionen
        let fields = [...this.standardFields];
        
        if (this.svgOptions && this.svgOptions.length) {
          this.svgOptions.forEach(option => {
            // Nur hinzufügen, wenn das Feld noch nicht existiert
            if (!fields.some(f => f.value === option.field)) {
              fields.push({
                value: option.field,
                text: `${option.label} (${option.field})`
              });
            }
          });
        }
        
        return fields;
      }
    },
    methods: {
      formatDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleString();
      },
      formatPrice(priceObj) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: priceObj.currency_code
        }).format(priceObj.amount / priceObj.divisor);
      },
      calculateTotal(item) {
        return {
          amount: this.generateGrossPriceWithDiscount(item.price.amount) * item.quantity,
          divisor: 1,
          currency_code: item.price.currency_code
        };
      },
      getStatusColor(status) {
        switch (status.toLowerCase()) {
          case 'completed':
            return 'green';
          case 'processing':
            return 'orange';
          case 'cancelled':
            return 'red';
          default:
            return 'grey';
        }
      },
      getAcsStatusColor(status) {
        if (status) {
          return 'green';
        } else {
          return 'red';
        }
      },
      openPersonalizationDialog(item, open = true) {
        this.currentItem = item;
        this.currentPersonalization = this.getCurrentPersonalizationString(item);
        this.personalizationLines = this.getPersonalizationLines(item);
        this.personalizationDialog = open;
        
        // SVG-Optionen laden und Preview zurücksetzen
        const sku = this.getInternalSKU(item.sku);
        this.loadSVGOptions(sku);
        this.preview = null;
        
        // Wenn keine Personalisierungszeilen vorhanden sind, füge eine leere Zeile hinzu
        if (this.personalizationLines.length === 0) {
          this.addPersonalizationLine();
        }
        
        // Zeige einen Hinweis an, wenn das Produkt nicht im Bestand ist
        if (!this.productExists(item.sku)) {
          this.showSnackbar('Produkt nicht im Bestand gefunden. Personalisierung könnte eingeschränkt sein.', 'warning');
        }
      },

      getPersonalizationText(item) {
        const personalization = item.variations.find(v => v.property_id === 54);
        return personalization ? personalization.formatted_value : '';
      },

      getCurrentPersonalizationString(item) {
        return item.variations
          .map(variation => `${variation.formatted_name}: ${variation.formatted_value}`)
          .join('\n');
      },

      getPersonalizationLines(item) {
        const lines = [];
        
        // Mapping basierend auf den tatsächlich verfügbaren Optionen
        item.variations.forEach(variation => {
          const lowerName = variation.formatted_name.toLowerCase();
          
          // Direktes Mapping basierend auf dem Namen der Variation
          if (lowerName === "motiv") {
            // Suche in svgOptions nach einem passenden Motivfeld
            let motivField = this.findFieldForType("motiv");
            
            // Wenn kein passendes Feld gefunden wurde, verwende Standard-Fallback
            if (!motivField) {
              // Prüfe, ob ein line5 existiert (für Kompatibilität mit bestehenden Daten)
              const line5Exists = this.svgOptions?.some(opt => opt.field === 'line5' && 
                (opt.label.toLowerCase().includes('motiv') || opt.label.toLowerCase().includes('design')));
              
              motivField = line5Exists ? 'line5' : 'motiv1';
            }
            
            lines.push({
              label: motivField,
              value: variation.formatted_value
            });
          } else if (lowerName === "personalization") {
            // Verarbeite Personalisierungstext - splitten nach Zeilen
            const personalizations = variation.formatted_value.split('\n');
            
            personalizations.forEach((line, index) => {
              // Bestimme das passende Feld für diese Zeile
              let fieldName = this.determineFieldForLine(index);
              lines.push({
                label: fieldName,
                value: line.trim()
              });
            });
          } else if (lowerName === "color" || lowerName === "farbe") {
            // Suche passendes Farbfeld
            let colorField = this.findFieldForType("color");
            
            if (!colorField) {
              colorField = 'color';
            }
            
            lines.push({
              label: colorField,
              value: variation.formatted_value
            });
          } else {
            // Fallback für andere Variationen
            lines.push({
              label: lowerName.replace(/[^a-z0-9]/g, ''),
              value: variation.formatted_value
            });
          }
        });

        // Mindestens eine Zeile sicherstellen
        if (lines.length === 0) {
          lines.push({ label: 'line1', value: '' });
        }

        return lines;
      },
      
      findFieldForType(type) {
        // Sucht in den SVG-Optionen nach einem passenden Feld für einen bestimmten Typ
        if (!this.svgOptions) return null;
        
        // Mapping von Typen zu möglichen Suchbegriffen
        const typeKeywords = {
          'motiv': ['motiv', 'design', 'bild', 'grafik', 'muster'],
          'color': ['color', 'farbe', 'colour', 'couleur'],
          'name': ['name', 'text', 'beschriftung', 'title']
        };
        
        // Suche nach einem Feld, dessen Etikett einen der Suchbegriffe enthält
        const keywords = typeKeywords[type] || [type];
        
        for (const option of this.svgOptions) {
          const lowerLabel = option.label.toLowerCase();
          if (keywords.some(keyword => lowerLabel.includes(keyword))) {
            return option.field;
          }
        }
        
        return null;
      },
      
      determineFieldForLine(index) {
        // Bestimmt das passende Feld für eine Personalisierungszeile basierend auf Index
        if (!this.svgOptions) {
          // Ohne SVG-Optionen nutzen wir die Standard-Reihenfolge
          return `line${index + 1}`;
        }
        
        // Prüfe, ob wir ein Namensfeld haben für die erste Zeile
        if (index === 0) {
          const nameField = this.findFieldForType('name');
          if (nameField) return nameField;
        }
        
        // Fallback zur lineX Benennung, unter Berücksichtigung bereits vergebener Felder
        const lineFields = this.svgOptions
          .filter(opt => opt.field.startsWith('line'))
          .map(opt => opt.field)
          .sort();
          
        if (lineFields.length > index) {
          return lineFields[index];
        }
        
        return `line${index + 1}`;
      },

      addPersonalizationLine() {
        const newLabel = this.personalizationLines.some(line => line.label.startsWith('motiv'))
          ? `motiv${this.personalizationLines.filter(line => line.label.startsWith('motiv')).length + 1}`
          : `line${this.personalizationLines.filter(line => line.label.startsWith('line')).length + 1}`;

        this.personalizationLines.push({
          label: newLabel,
          value: ''
        });
      },

      removePersonalizationLine(index) {
        this.personalizationLines.splice(index, 1);
      },


      savePersonalization(close = false) {
        if (this.currentItem) {
          // Speichere die benutzerdefinierten Personalisierungen im aktuellen Item
          this.currentItem.customPersonalization = this.personalizationLines;
          
          // Aktualisiere den currentPersonalization String für die Anzeige
          this.currentPersonalization = this.personalizationLines
            .map(line => `${line.label}: ${line.value}`)
            .join('\n');
          
          // Generiere das JSON neu mit den aktualisierten Daten
          this.generateJSON();
          
          // Zeige Erfolgsmeldung an
          this.showSnackbar('Personalisierung gespeichert', 'success');
        }
        
        // Dialog schließen, wenn close=true ist
        if (close) {
          this.personalizationDialog = false;
        }
      },

      generateGrossPriceWithDiscount(price,divisor) {
        let itemCount = this.order.transactions.length;
        let discount = this.order.discount_amt.amount / itemCount;
        return ((price) - discount) / divisor;
      },

      getDiscountedPrice(item) {
        return this.generateGrossPriceWithDiscount(item.price.amount,item.price.divisor);
      },

      generateJSON() {
        if (!this.order) return;

        this.generatedJSON = {
          reference: `${this.order.receipt_id}`,
          createDate: new Date().toISOString(),
          shipping: { type: "Standard" },
          firstname: this.editableCustomerInfo.firstName,
          lastname: this.editableCustomerInfo.lastName,
          street: this.editableCustomerInfo.street,
          housenumber: this.editableCustomerInfo.houseNumber,
          additional: this.editableCustomerInfo.additionalInfo,
          email: this.editableCustomerInfo.email,
          zip: this.editableCustomerInfo.zipCode,
          city: this.editableCustomerInfo.city,
          country: this.editableCustomerInfo.country,
          shippingCost: this.order.total_shipping_cost.amount / this.order.total_shipping_cost.divisor,
          orderItems: this.order.transactions.map(item => {
            const stockItem = this.stockData.find(stock => stock.externalSKU === item.sku);
            const baseItem = {
              id: item.transaction_id.toString(),
              sku: stockItem ? stockItem.sku : item.sku,
              qty: item.quantity,
              grossPrice: item.price.amount / item.price.divisor,
              discount: (this.order.discount_amt.amount / this.order.transactions.length) / item.price.divisor,
            };

            // Fügen Sie die benutzerdefinierten Personalisierungsfelder hinzu
            if (item.customPersonalization) {
              item.customPersonalization.forEach(field => {
                baseItem[field.label] = field.value;
              });
            } else {
              // Falls keine benutzerdefinierten Personalisierungen vorhanden sind, 
              // verwenden Sie die ursprünglichen Variationen
              item.variations.forEach(variation => {
                if (variation.formatted_name.toLowerCase() === "motiv") {
                  baseItem.motiv1 = variation.formatted_value;
                } else if (variation.formatted_name.toLowerCase() === "personalization") {
                  variation.formatted_value.split('\n').forEach((line, index) => {
                    baseItem[`line${index + 1}`] = line.trim();
                  });
                }
              });
            }

            return baseItem;
          }),
        };
      },

      async initBasicTranslation() {
        for (let transaction of this.order.transactions) {
          this.openPersonalizationDialog(transaction, false);
          this.savePersonalization(false);
        }

      },
      async fetchOrderData() {
        try {
          const [orderResponse, stockResponse] = await Promise.all([
            axios.get(`https://etsy.acs.accelery.de/api/order?orderId=${this.orderId}`),
            axios.get('https://etsy.acs.accelery.de/api/stock')
          ]);

          this.order = orderResponse.data;
          this.stockData = stockResponse.data;

          this.initializeEditableCustomerInfo();
          this.initBasicTranslation();


          this.generateJSON();
        } catch (error) {
          console.error('Fehler beim Abrufen der Daten:', error);
          this.showSnackbar('Fehler beim Laden der Daten', 'error');
        }
      },

      getInternalSKU(externalSKU) {
        const stockItem = this.stockData.find(item => item.externalSKU === externalSKU);
        return stockItem ? stockItem.sku : 'Nicht gefunden';
      },

      productExists(externalSKU) {
        return this.stockData.some(item => item.externalSKU === externalSKU);
      },

      initializeEditableCustomerInfo() {
        const nameParts = this.order.name.split(' ');
        // Extrahieren der Hausnummer aus dem Straßenfeld
        let street = this.order.first_line || '';
        let houseNumber = '';

        // Regex-Muster für typische Hausnummern (z.B. "12", "12a", "12-14")
        const houseNumberPattern = /([0-9]+[a-zA-Z]?(?:-[0-9]+)?)\s*$/;
        const match = street.match(houseNumberPattern);
        
        if (match) {
          houseNumber = match[1];
          // Entfernen der Hausnummer aus dem Straßenfeld
          street = street.replace(houseNumberPattern, '').trim();
        }

        this.editableCustomerInfo = {
          firstName: nameParts[0] || '',
          lastName: nameParts.slice(1).join(' ') || '',
          street: street,
          houseNumber: houseNumber,
          additionalInfo: this.order.second_line || '',
          zipCode: this.order.zip || '',
          city: this.order.city || '',
          country: this.order.country_iso || '',
          email: this.order.buyer_email || ''
        };
      },

      async reset() {
        this.order = null;
        this.generatedJSON = null;
        this.currentItem = null;
        this.svgOptions = null;
        await this.fetchOpenOrders();
      },


      async importOrder() {
        this.importing = true;
        
        try {
          // Lokalen Datensatz sofort als importiert markieren
          if (this.order) {
            this.order.acsImported = true;
            
            // Wenn wir in der Tabelle den aktuellen Auftrag haben, dort auch aktualisieren
            const orderIndex = this.openOrders.findIndex(o => o.receipt_id === this.order.receipt_id);
            if (orderIndex !== -1) {
              this.openOrders[orderIndex].acsImported = true;
            }
          }
          
          // Erfolgsmeldung direkt anzeigen, ohne auf den Server zu warten
          this.showSnackbar('Bestellung wird importiert...', 'info');
          
          // Daten an Server senden
          await axios.post('https://etsy.acs.accelery.de/api/place', 
            this.manipulatedJSON ? JSON.parse(this.manipulatedJSON) : this.generatedJSON
          );
          
          // Erfolgsmeldung anzeigen
          this.showSnackbar('Bestellung erfolgreich importiert', 'success');
          
          // Zurücksetzen und Tabelle aktualisieren
          await this.fetchOpenOrders();
          this.reset();
        } catch (error) {
          console.error('Fehler beim Importieren der Bestellung:', error);
          
          // Bei Fehler den Status zurücksetzen
          if (this.order) {
            this.order.acsImported = false;
            
            // Auch in der Tabelle zurücksetzen
            const orderIndex = this.openOrders.findIndex(o => o.receipt_id === this.order.receipt_id);
            if (orderIndex !== -1) {
              this.openOrders[orderIndex].acsImported = false;
            }
          }
          
          this.showSnackbar('Fehler beim Importieren der Bestellung', 'error');
        } finally {
          this.importing = false;
        }
      },
      showSnackbar(text, color = 'success') {
        this.snackbarText = text;
        this.snackbarColor = color;
        this.snackbar = true;
      },

      loadSVGOptions(sku) {
        if (!sku || sku === 'Nicht gefunden') {
          this.svgOptions = null;
          return;
        }
        
        let url = `https://svg.accelery.host/public/product/${sku}`;
        axios.get(url)
          .then(response => {
            let data = (response.data);
            this.$nextTick(() => {
              this.svgOptions = data.options;
              // Aktualisiere die Personalisierungszeilen basierend auf den SVG-Optionen
              this.updatePersonalizationBasedOnSVG();
            });
          })
          .catch(error => {
            console.error('Fehler beim Laden der SVG-Optionen:', error);
            this.svgOptions = null;
          });
      },


      async showSVGStudioPreview() {
        // Speichern der Personalisierung, ohne Dialog zu schließen
        this.savePersonalization(false);

        if (!this.currentItem) return;

        const sku = this.getInternalSKU(this.currentItem.sku);
        if (!sku || sku === 'Nicht gefunden') {
          this.showSnackbar('Kein gültiges SKU gefunden', 'warning');
          return;
        }

        const options = {};
        // Personalisierungsdaten sammeln
        if (this.currentItem.customPersonalization) {
          this.currentItem.customPersonalization.forEach(field => {
            // Nur Felder mit Werten hinzufügen
            if (field.value) {
              options[field.label] = field.value;
            }
          });
        }

        // Prüfen, ob es Personalisierungsdaten gibt
        if (Object.keys(options).length === 0) {
          this.showSnackbar('Keine Personalisierungsdaten vorhanden', 'warning');
          return;
        }

        // Laden-Anzeige aktivieren und Vorschau anfordern
        this.loading = true;
        this.showSnackbar('Vorschau wird geladen', 'info');
        
        try {
          const url = `https://svg.accelery.host/public/product/${sku}/preview`;
          const response = await axios.post(url, options);
          const data = response.data;
          
          this.$nextTick(() => {
            this.preview = data.preview;
            if (this.preview) {
              this.showSnackbar('Vorschau erfolgreich geladen', 'success');
            } else {
              this.showSnackbar('Keine Vorschau verfügbar', 'warning');
            }
          });
        } catch (error) {
          console.error('Fehler beim Laden der Vorschau:', error);
          this.showSnackbar('Fehler beim Laden der Vorschau', 'error');
        } finally {
          this.loading = false;
        }
      },

      //Open Orders
      async fetchOpenOrders() {
        this.loadingOpenOrders = true;
        try {
          const response = await axios.get('https://etsy.acs.accelery.de/api/orders');
          this.openOrders = response.data;
        } catch (error) {
          console.error('Fehler beim Abrufen der offenen Bestellungen:', error);
          this.showSnackbar('Fehler beim Laden der offenen Bestellungen', 'error');
        } finally {
          this.loadingOpenOrders = false;
        }
      },

      formatPrice(price) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: price.currency_code
        }).format(price.amount / price.divisor);
      },

      formatDate(timestamp) {
        return new Date(timestamp * 1000).toLocaleString('de-DE');
      },

      loadOrderDetails(orderId) {
        this.orderId = orderId;
        this.fetchOrderData();
      },

      getFieldHint(fieldName) {
        // Hinweis für das Feld anzeigen, basierend auf SVG-Optionen
        if (this.svgOptions) {
          const option = this.svgOptions.find(opt => opt.field === fieldName);
          if (option) {
            return `SVG-Feld: ${option.label}`;
          }
        }
        
        // Standardhinweise für bestimmte Felder
        if (fieldName.startsWith('line')) {
          return 'Textzeile für die Personalisierung';
        } else if (fieldName.startsWith('motiv')) {
          return 'Motivbezeichnung';
        } else if (fieldName === 'color') {
          return 'Farbwert';
        }
        
        return '';
      },

      updatePersonalizationBasedOnSVG() {
        // Wenn SVG-Optionen verfügbar sind, prüfen und ergänzen
        if (!this.svgOptions || !this.svgOptions.length || !this.personalizationLines) {
          return;
        }
        
        // Bestehende Feldnamen in den Personalisierungszeilen
        const existingFields = this.personalizationLines.map(line => line.label);
        
        // Prüfe und ergänze fehlende erforderliche oder empfohlene Felder
        this.svgOptions.forEach(option => {
          // Überspringe bereits vorhandene Felder
          if (existingFields.includes(option.field)) {
            return;
          }
          
          // Füge erforderliche Felder hinzu
          if (option.required) {
            this.personalizationLines.push({
              label: option.field,
              value: ''
            });
          }
        });
        
        // Sortiere die Personalisierungszeilen nach der Reihenfolge in SVG-Optionen
        this.personalizationLines.sort((a, b) => {
          const indexA = this.getFieldOrderIndex(a.label);
          const indexB = this.getFieldOrderIndex(b.label);
          return indexA - indexB;
        });
      },
      
      getFieldOrderIndex(fieldName) {
        // Findet den Index eines Feldes in der SVG-Optionsliste
        if (!this.svgOptions) return 999;
        
        const option = this.svgOptions.findIndex(opt => opt.field === fieldName);
        if (option >= 0) return option;
        
        // Wenn nicht in den Optionen, dann standardmäßige Sortierung
        if (fieldName.startsWith('line')) {
          const num = parseInt(fieldName.replace('line', '')) || 100;
          return 100 + num; // line Felder nach anderen Feldern
        }
        
        return 999; // Unbekannte Felder ans Ende
      },

    },
    mounted() {
      this.fetchOpenOrders();
    },
    watch: {
      editableCustomerInfo: {
        handler() {
          this.generateJSON();
        },
        deep: true
      }
    }
  };
</script>